import styled from 'styled-components';
import {
  TestimonialBox,
  TestimonialText,
  CustomerImage,
  CustomerInfo,
  CustomerName,
  CustomerPosition,
} from '@components/pages/home/styles';

export const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.5rem;
  color: ${props => props.theme.breadcrumbColor};
  letter-spacing: 0;
  span,
  svg {
    opacity: 0.5;
  }
  svg {
    margin: 0.5rem 1.2rem 0;
  }
  span.selected {
    opacity: 1;
  }
`;

export const ServiceDetails = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0;
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: auto auto;
    margin: 0 0 4rem 15rem; // 0 0 18rem 15rem;
  }
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  ${Breadcrumb} {
    margin-top: 3.8rem;
  }
  @media (max-width: 1200px) {
    margin-top: 3.8rem;
    padding: 0;
    ${Breadcrumb} {
      display: none;
    }
  }
`;

export const Subtitle = styled.h2`
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.2rem;
  color: ${props => props.theme.subtitleColor};
  letter-spacing: 0.16rem;
  line-height: 2.8rem;
  text-align: left;
  margin: ${props => props.margin || '2rem 1.5rem 1.4rem'};
  text-transform: uppercase;
  @media (min-width: 1200px) {
    text-align: ${props => props.align || 'center'};
  }
`;

export const Title = styled.h1`
  font-family: 'Heuristica';
  font-weight: 600;
  font-size: 2.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  line-height: 2.8rem;
  margin: 0 1.5rem;
  @media (min-width: 1200px) {
    text-align: left;
    margin: 0 0 1.1rem 0;
  }
`;

export const Content = styled.div`
  font-family: 'Avenir Book';
  font-size: 1.6rem;
  color: ${props => props.theme.paragraphColor};
  letter-spacing: 0;
  line-height: 2.6rem;
  margin: 0 1.5rem;
  @media (min-width: 1200px) {
    margin: 0 7.7rem 4.7rem 0;
  }
  b {
    font-family: 'Heuristica';
    font-weight: 400;
    font-size: 1.8rem;
    color: ${props => props.theme.txtColor};
    letter-spacing: 0;
    line-height: 4.2rem;
  }
`;

export const SideBox = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  @media (min-width: 1200px) {
    width: 69.8rem;
    display: flex;
    flex-direction: column;
    img {
      width: 69.8rem;
    }
  }

  ${Breadcrumb} {
    margin: 14px 1.4rem;
    @media (min-width: 1200px) {
      display: none;
    }
  }
  ${TestimonialBox} {
    width: 100%;
    padding: 0;
    background-color: ${props => props.theme.secondaryBgColor};
    padding-top: 2rem;
    @media (min-width: 1200px) {
      position: absolute;
      top: 35rem;
      right: 0;
      width: 56.8rem;
      margin: 0;
    }
  }
  ${TestimonialText} {
    font-family: 'Avenir Book Oblique';
    font-size: 1.4rem;
    color: ${props => props.theme.quoteTxtColor};
    letter-spacing: 0;
    line-height: 2.4rem;
    padding: 0px 2.4rem;
    @media (min-width: 1200px) {
      padding: 0;
      margin: 4.3rem 2.4rem 2.1rem 5.7rem;
    }
  }
  ${CustomerInfo} {
    margin: 0 2.4rem 2.1rem 2.7rem;
    img {
      width: 12rem;
    }
  }
  ${CustomerImage} {
    width: auto;
    height: auto;
    box-shadow: none;
    img {
      width: 5.1rem;
      height: 5.1rem;
    }
  }
  ${CustomerName} {
    font-size: 1.4rem;
  }
  ${CustomerPosition} {
    font-size: 1.4rem;
  }
`;
