import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'styled-components';
import ArrowRight from '@assets/icons/arrow-right';

import { Container, Section } from '@components/styledComponents/index';
import Slider, {
  Slide,
  SlideImage,
  SlideTitle,
  SlideText,
} from '@components/slider/index';
import {
  Breadcrumb,
  Content,
  DetailsBox,
  ServiceDetails,
  SideBox,
  Subtitle,
  Title,
} from './styles';
/* import {
  CustomerDetails,
  CustomerDetailsBox,
  CustomerImage,
  CustomerInfo,
  CustomerLogo,
  CustomerName,
  CustomerPosition,
  TestimonialBox,
  TestimonialText,
} from '../home/styles'; */

const Service = props => {
  const theme = useContext(ThemeContext);
  const [service, setService] = useState(null);
  const {
    site: {
      siteMetadata: { services },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            services {
              image
              image2x
              subtitle
              title
              desc
              link
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!services) {
      return;
    }
    const _service = services.find(
      item => process.env.BaseURL + item.link === props.location.pathname
    );
    setService(_service);
  }, [props.location.pathname, services]);

  return (
    <>
      {service && (
        <ServiceDetails>
          <DetailsBox>
            <Breadcrumb>
              <span>Services</span>
              <ArrowRight fill={theme.breadcrumbColor} />
              <span className="selected">{service.title}</span>
            </Breadcrumb>
            <Subtitle margin={'3.8rem 1.5rem 1.8rem'} align={'left'}>
              {service.subtitle}
            </Subtitle>
            <Title>{service.title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: service.desc }} />
          </DetailsBox>
          <SideBox>
            <Breadcrumb>
              <span>Services</span>
              <ArrowRight fill={theme.breadcrumbColor} />
              <span className="selected">{service.title}</span>
            </Breadcrumb>
            <img
              alt={service.image}
              src={require('@images/' + service.image)}
              srcSet={`${require('@images/' +
                service.image)}, ${require('@images/' + service.image2x)} 2x`}
            />
            {/* <TestimonialBox>
              <TestimonialText>
                “Thank you so much for your visit last week and for your honest,
                clear and professional advice. We were very impressed with how
                down-to-earth and straightforward you were in sharing your
                knowledge of planning etc with us.”
              </TestimonialText>
              <CustomerInfo>
                <CustomerDetailsBox>
                  <CustomerImage>
                    <img
                      src={require('@images/client.png')}
                      alt="One of our clients"
                    />
                  </CustomerImage>

                  <CustomerDetails>
                    <CustomerName>Simon Barnes</CustomerName>
                    <CustomerPosition>VP of Technology</CustomerPosition>
                  </CustomerDetails>
                </CustomerDetailsBox>
                <CustomerLogo
                  alt="One of our customer logo"
                  src={require('@images/clients/mandiri-bank.png')}
                  srcSet={`${require('@images/clients/mandiri-bank.png')}, ${require('@images/clients/mandiri-bankx2.png')} 2x`}
                />
              </CustomerInfo>
            </TestimonialBox> */}
          </SideBox>
        </ServiceDetails>
      )}

      <Container deskDir={'column'} bgColor={theme.secondaryBgColor}>
        <Section>
          <Subtitle margin={'0 0 2.9rem 0'}>Our expertise</Subtitle>
          <Slider>
            <Slide>
              <SlideImage
                src={require('@images/software-quality-cost.jpg')}
                srcSet={`${require('@images/software-quality-cost.jpg')}, ${require('@images/software-quality-cost@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  Failure and Beyond: How Software Quality Might Cost You Your
                  Life!
                </SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                {/* <SlideMore to={'/'}>Read More</SlideMore> */}
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/tes-tmanagemnet-office.jpg')}
                srcSet={`${require('@images/tes-tmanagemnet-office.jpg')}, ${require('@images/tes-tmanagemnet-office@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>Test Management Office “TMO”</SlideTitle>
                <SlideText>
                  A high quality product that meets customer needs is the
                  ultimate common goal that all testing teams thrive to achieve.
                  Testing activities and processes should be well planned,
                  prepared, executed and effectively tracked and monitored to
                  ensure meeting that goal, and test management…
                </SlideText>
                {/* <SlideMore to={'/'}>Read More</SlideMore> */}
              </div>
            </Slide>
            <Slide>
              <SlideImage
                src={require('@images/failure-and-beyond.jpg')}
                srcSet={`${require('@images/failure-and-beyond.jpg')}, ${require('@images/failure-and-beyond@2x.jpg')} 2x`}
              />
              <div>
                <SlideTitle>
                  Failure and Beyond: How Software Quality Might Cost You Your
                  Life!
                </SlideTitle>
                <SlideText>
                  In this rapidly changing world where software has become an
                  integrated necessity in most of life’s aspects, one misstep
                  can be costly. In some cases, it could be fatal to your
                  business and your life.
                </SlideText>
                {/* <SlideMore to={'/'}>Read More</SlideMore> */}
              </div>
            </Slide>
          </Slider>
        </Section>
      </Container>
    </>
  );
};

export default Service;
