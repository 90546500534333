import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Service from '@components/pages/service/index';

const ServicePage = (props) => {
  return (
    <Layout {...props}>
      <SEO title="Service" />
      <Service {...props} />
    </Layout>
  );
};

export default ServicePage;
