import React from 'react';

const ArrowRight = ({ fill }) => (
  <svg width="8px" height="12px" viewBox="0 0 8 12">
    <title>Arrow Right</title>
    <defs>
      <path
        d="M3.95041304,2 L-1.3604465,7.67439824 C-1.72030302,8.05888764 -1.72030302,8.65657321 -1.3604465,9.04106261 L-1.30082647,9.10476375 C-0.939028058,9.49132797 -0.332360282,9.51140462 0.054203935,9.14960621 C0.0693878989,9.13539503 0.0841054103,9.12069353 0.098333106,9.10552504 L3.95041304,4.99872952 L3.95041304,4.99872952 L7.80249297,9.10552504 C8.16471182,9.49169532 8.77140109,9.51111178 9.15757136,9.14889292 C9.17273985,9.13466523 9.18744136,9.11994772 9.20165254,9.10476375 L9.26127257,9.04106261 C9.62112909,8.65657321 9.62112909,8.05888764 9.26127257,7.67439824 L3.95041304,2 L3.95041304,2 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="Final-Web---Master"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Wareef_Desktop_1.B"
        transform="translate(-240.000000, -3426.000000)"
      >
        <g id="Group-12" transform="translate(-212.000000, 1389.000000)">
          <g id="Group-3-Copy-2" transform="translate(0.000000, 1730.000000)">
            <g id="Group-8" transform="translate(363.000000, 107.000000)">
              <g id="Button-" transform="translate(0.000000, 192.000000)">
                <g id="Arrow-Right" transform="translate(89.000000, 8.000000)">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <use
                    id="Shape"
                    fill={fill || '#F9C015'}
                    transform="translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) "
                    xlinkHref="#path-1"
                  ></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowRight;
